import './DeckItem.scss'

export function DeckItem ({ iconClass, onClick, className = '' }) {
    return (

        <div onClick={onClick} className={"c-deck " + className}>
            <div className="c-deck__container">
                <span className="c-deck__card c-deck__card--left icon-exclamation"/>
                <span className={"c-deck__card " + iconClass}/>
                <span className="c-deck__card c-deck__card--right icon-exclamation"/>
            </div>
        </div>

    )
}
