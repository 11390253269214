import styles from "./Card.module.scss";
import { useTranslation } from "react-i18next";

export default function Card({data}) {
    const {t} = useTranslation();

    console.log('##ABEL## >> default >>  Card', data);
    return  <div className={styles["c-card"]}>
        {data.attributes.num && <span className={styles["c-card__num"]}>{t("Intensity") + " "}{data.attributes.num}/10</span>}
        <h2 className={styles["c-card__description"]}>
            {data.attributes.description}
        </h2>
    </div>
}
