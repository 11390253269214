export const categoriesConst = {
    es: [
        'Divertido',
        'Picante',
        'Caliente',
        'Extremo',
        'XXX'
    ],
    en: [
        'Funny',
        'Spicy',
        'Hot',
        'Extreme',
        'XXX'
    ]

}
