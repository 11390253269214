import React from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function PlayersForm ({ fnCreatePlayer }) {

    const { t } = useTranslation();

    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (formData) => {
        fnCreatePlayer(formData);
        reset();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>
                <span className="b-text-label">{t('Player name')}</span>
                <input className="b-input" type="text" name="name" ref={register}/>
            </label>

            <input className="b-btn" type="submit" value={t('Add')}/>
        </form>
    )
}
