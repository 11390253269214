import React from 'react';
import './TextTransition.scss';

export function TextTransition({text, textColor, onClick}){
    return (
        <div className="c-text-transition">
            <a className="c-text-transition__front-text" onClick={onClick} style={{color: textColor}}>
                {text}
            </a>
            <div className="c-text-transition__content">
                <div className="c-text-transition__inner">
                    <span  style={{color: textColor}} className="c-text-transition__text">{text}</span>
                    <span  style={{color: textColor}} className="c-text-transition__text">{text}</span>
                    <span  style={{color: textColor}} className="c-text-transition__text">{text}</span>
                    <span  style={{color: textColor}} className="c-text-transition__text">{text}</span>
                </div>
            </div>
        </div>
    )
}
