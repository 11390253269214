import React from 'react';
import './Menu.scss'
import { NavLink, useLocation } from "react-router-dom";
import { DeckItem } from "../../../pages/DecksPage/components/DeckItem/DeckItem"; // with import


export default function Menu () {

    const pathname = useLocation().pathname;

    return (
        pathname !== '/' && <nav className="c-menu">

            {/*<NavLink*/}
            {/*    to="/cards" activeClassName="c-menu__link--active"*/}
            {/*    className="c-menu__link justify-content-center"><span className="b-mini-card icon-question"/></NavLink>*/}
            {/*<NavLink*/}
            {/*    to="/decks" activeClassName="c-menu__link--active"*/}
            {/*    className="c-menu__link justify-content-center"><span className="b-mini-card icon-question"/></NavLink>*/}
            <NavLink
                to="/decks" activeClassName="c-menu__link--active"
                className="c-menu__link justify-content-center"> <DeckItem className="c-deck--mini c-deck--shuffled"
                                                                           iconClass="icon-exclamation"/></NavLink>
            <NavLink to="/" activeClassName="c-menu__link--active mx-4 mx--lg-7"
                     className="c-menu__link"><span className="b-text-start">CUDECKS</span></NavLink>
        {/*    <div className="d-flex justify-content-center">
                <NavLink to="/profile" activeClassName="c-menu__link--active"
                         className={"c-menu__link b-icon b-icon--s icon-profile-user"}/>
            </div>*/}
            <div className="d-flex justify-content-center">
                <NavLink to="/profile" activeClassName="c-menu__link--active"
                         className={"c-menu__link"}><span className="b-mini-card icon-user"/></NavLink>
            </div>

            {/*<div className="d-flex justify-content-center">*/}
            {/*    <NavLink to="/information" activeClassName="c-menu__link--active"*/}
            {/*             className={"c-menu__link"}><span className="b-mini-card"> i</span></NavLink>*/}
            {/*</div>*/}

        </nav>
    );

}
