import React from 'react';

export function PlayersList ({ players, fnRemovePlayer }) {
    return (
        <div>
            {players.map((player, i) => <div className="my-5 d-flex align-items-center justify-content-between" key={i}>
                <div className="d-flex align-items-center">
                    <span style={{ color: player.color }} className="b-icon icon-profile-user mr-4"/>
                    <h4>{player.name}</h4>
                </div>
                <span className="b-mini-card b-mini-card--l icon-cancel" onClick={() => fnRemovePlayer(i)}/>
            </div>)}
        </div>
    )
}
