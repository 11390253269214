import React, { useContext, useEffect, useState } from 'react';
import './BoardGame.scss';
import { useTranslation } from "react-i18next";
import Card from "../../../../core/components/Card/Card";
import { PlayerContext } from "../../../../shared/contexts/PlayerContext";

export default function BoardGame ({ card, onDone }) {
    const [boardCard, setBoardCard] = useState(card);
    const { t } = useTranslation()
    console.log('##ABEL## >> default >>  BoardGame', boardCard);
    const localStorageGameConfig = JSON.parse(localStorage.getItem("gameConfig"))

    const { actualPlayer, setActualPlayer, shuffledPlayers, setShuffledPlayers } = useContext(PlayerContext);

    const init = () => {
        if (card) {
            console.log('##ABEL## >> default >>  init', card.attributes.type === "Mime");
            if (card.attributes.type === "Mime") {
                setBoardCard({
                    id: card.id,
                    attributes: { ...card.attributes, num: Math.floor((Math.random() * 10) + 1) }
                })
            } else {
                setBoardCard(card)
            }
        }
    }

    const nextStep = (score) => {
        console.log('##ABEL## >> default >>  nextStep', card);
        if (boardCard.attributes.answer) {
            setBoardCard({ ...boardCard, attributes: { description: boardCard.attributes.answer, answer: null } })
        } else {
            if (localStorageGameConfig.isCountingScore) {
                changeUserScore(score)
            }
            onDone();
        }
    }

    const changeUserScore = (score) => {
        const index = shuffledPlayers.findIndex(player => player.name === actualPlayer.name)
        if (index !== -1) {
            shuffledPlayers[index] = { ...shuffledPlayers[index], score }
            setShuffledPlayers(shuffledPlayers);
            setActualPlayer({ ...actualPlayer, score })
        }
    }


    useEffect(init, [card])

    return (
        <div className="c-board-game">
            {boardCard && <>
                <div className="c-board-game__content">
                    <Card data={boardCard}/>
                </div>
                <div className={"c-board-game__btn"}>
                    {!localStorageGameConfig.isCountingScore && <button className={"b-btn"}
                                                                        onClick={nextStep}>{boardCard.attributes.answer ? t("Show" +
                        " answer") : t("Done!")}</button>}
                    {!boardCard.attributes.answer && localStorageGameConfig.isCountingScore &&
                    <div className={"d-flex"}>
                        <button className={"mr-4 b-btn"}
                                onClick={() => nextStep(actualPlayer.score - 1)}>{"-1"}</button>
                        <button className={"mr-4 b-btn"}
                                onClick={() => nextStep(actualPlayer.score)}>{"0"}</button>
                        <button className={"b-btn"}
                                onClick={() => nextStep(actualPlayer.score + 1)}>{"+1"}</button>
                    </div>}
                </div>
            </>}
        </div>
    )
}
