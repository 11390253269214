import { API } from "../consts/api.const";
import qs from "qs";


export const getDecks = (query) => {
    query = query ? query : qs.stringify(
        {
            populate: ['user'],
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    return API.get(`decks?${query}`);
}


export const getDeck = (idDeck, query) => {
    query = query ? query : qs.stringify(
        {
            populate: {

                user: { populate: '*' },
                cards: { populate: '*' },
                duplicateCards: { populate:{card:{fields: ["id"]}} }
            }
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    return API.get(`decks/${idDeck}?${query}`)
}


export const putDeck = (idDeck, body, query) => {
    query = query ? query : qs.stringify(
        {
            populate: {
                user: { populate: '*' },
                cards: { populate: '*' },
                duplicateCards: { populate:{card:{fields: ["id"]}} }
            }        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    return API.put(`decks/${idDeck}?${query}`, body)
}
