export const es = {
    'Name': 'Nombre',
    'Email': 'Email',
    'Password': 'Contraseña',
    'Rules': 'Reglas',
    'Add new card': 'Añadir nueva carta',
    'Confirm': 'Confirmar',
    'Add': 'Añadir',
    'You are not logged in': 'No estás conectado',
    'Save': 'Guardar',
    'Card description': 'Descripción de la carta',
    'Welcome': 'Bienvenido',
    'Edit user': 'Editar usuario',
    'Sign out': 'Desconectar',
    'Sign in': 'Registrarse',
    'Log in': 'Conectarse',
    'Privacy Policy': 'Política de privacidad',
    'Truth': 'Verdad',
    'Dare': 'Reto',
    'Both': 'Ambos',
    'Done': 'Hecho',
    'Player name': 'Nombre de jugador',
    'Random player': 'Jugador aleatorio',
    'Add new deck': 'Añadir nueva baraja',
    'Card name': 'Nombre de la carta',
    'Deck name': 'Nombre de la baraja',
    'Deck cards': 'Cartas del deck',
    'Official cards': 'Cartas oficiales',
    'Personal cards': 'Cartas personales',
    'Select category': 'Selecciona una categoría',
    'Select categories': 'Selecciona categorías',
    'Select type': 'Selecciona un tipo',
    'Select deck': 'Selecciona una baraja',
    'Manage Cards': 'Administrar cartas',
    'There is no more cards. Please, reset game to keep playing': 'No hay más cartas. Por favor, reinicia el juego' +
        ' para seguir jugando',
    'Create your decks': 'Crea tus barajas',
    'Create a new card': 'Crea una nueva carta',
    'Filter': 'Filtrar',
    'Filter cards': 'Filtrar cartas',
    'Edit or add deck cards': 'Edita o agrega cartas',
    'Create and account to add your own cards and modify decks': 'Crea una cuenta para' +
        ' añadir tus propias cartas y modificar las barajas',
    'Custom your game experience!': 'Personaliza tu juego!',

    // InfoPage
    'Start game': 'Empieza el juego',
    'Add players': 'Añade jugadores',
    'Select your deck': 'Selecciona una baraja',
    'Every deck is a custom experience':
        'Cada baraja es una experiencia diferente',
    'You can create an account to add new cards or custom you personal decks':
        'Puedes crear una cuenta para añadir nuevas cartas y modificar tus barajas personales',
    'Play':
        'Juega',
    'Click random player. User need to pick a card. Do what the card say':
        'Haz click en jugador aleatorio. El jugador tiene que escoger una carta y hacer lo que la carta pida ',
'Search a Card to add it': "Busca una Carta para añadirla",
    "Add new cards": "Añade nuevas cartas",
    'Are you sure?': "¿Estás seguro?",
    "You will delete this card forever from all decks": "Eliminarás esta carta para siempre de todos los decks",
    "Turn of": "Le toca a",
    "Draw card": "Robar card",
    "Done!":"¡Hecho!",
    "Add your answer here": "Agrega aquí tu respuesta",
    "Show answer":"Mirar respuesta",
    "Cancel":"Cancelar",
    "Intensity":"Intensidad",
    "Configure your game": "Configura tu juego",
    "Config Game":"Configurar juego",
    "Enable score":"Habilitar puntuación",
    "Points":"Puntos",
    "Turn":"Turno",
    "Round":"Ronda"
}
