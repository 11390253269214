import React from 'react';
import { Link } from "react-router-dom";
import { TextTransition } from "../../shared/components/TextTransition/TextTransition";
import { categoriesConst } from "../../shared/consts/categories.const";

export default function HomePage () {
    return (
        <div className="o-absolute-center">
            <TextTransition text={"Play Game"}/>
            <TextTransition text={"Play Game"}/>
            <TextTransition text={"Play Game"}/>
            <TextTransition text={"Play Game"}/>
            <div className="my-8">
                <div className="d-flex justify-content-center">
                    {categoriesConst.en.map((category, i) => <span key={i} style={{color: 'var(--color-' + category.toLowerCase() + ')'}}
                        className={"b-icon icon-" + category.toLowerCase()}/>)}
                </div>
                <Link className="b-text-start" to="/players">Start game</Link>
            </div>
            <TextTransition text={"Play Game"}/>
            <TextTransition text={"Play Game"}/>
            <TextTransition text={"Play Game"}/>
            <TextTransition text={"Play Game"}/>

        </div>
    )
}
