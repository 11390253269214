import React, { useEffect, useState } from 'react';
import { SelectRandomUser } from "./components/SelectRandomUser/SelectRandomUser";
import { useTranslation } from "react-i18next";
import BoardGame from "./components/BoardGame/BoardGame";
import { shuffleArrayUtil } from "../../shared/utils/shuffleArrayUtil";
import { getDeck } from "../../shared/queries/deck.queries";
import { PlayerContext } from "../../shared/contexts/PlayerContext";
import { useHistory } from "react-router-dom";

let allShuffleCards = [];
let turnCount;
let roundCount;

export default function GamePage () {
    const players = JSON.parse(localStorage.getItem('players'));

    const [actualCard, setActualCard] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [noMoreCards, setNoMoreCards] = useState(true);
    const [shuffledPlayers, setShuffledPlayers] = useState(null);
    const [playersLeft, setPlayersLeft] = useState(null);

    const [actualPlayer, setActualPlayer] = useState(null);
    const { t } = useTranslation();
    const history = useHistory();

    const init = async () => {

        shuffleAndSelectFirstPlayer();
        const idDeck = localStorage.getItem("deck");
        const { data: { data: { attributes: { duplicateCards, cards: { data: cards } } } } } = await getDeck(idDeck);
        console.log('##ABEL## >> default >>  init', duplicateCards);
        turnCount = 1;
        roundCount = 1;

        setNoMoreCards(false)
        const allCards = duplicateAllCards(cards, duplicateCards);
        allShuffleCards = shuffleArrayUtil(shuffleArrayUtil(allCards));
        console.log('##ABEL## >> default >>  init', allShuffleCards);
    }

    const shuffleAndSelectFirstPlayer = () => {
        if (players) {
            const localShuffledPlayers = shuffleArrayUtil(players)
            setShuffledPlayers(localShuffledPlayers);
            nextPlayer(localShuffledPlayers)
        } else{
            history.push("/players");
        }
    }


    const playerSelected = () => {
        if (turnCount > 0) {
            setIsPlaying(!isPlaying);
            selectCard();
        }
    }

    const selectCard = () => {
        if (allShuffleCards.length < 1) {
            setNoMoreCards(true);
        } else {
            setActualCard(allShuffleCards[0]);
        }
    }

    const selectedCardAndChangePlayer = (selectedCard) => {
        removeSelectedCardFromGame(selectedCard);
        setIsPlaying(!isPlaying);
        nextPlayer(playersLeft)
    }

    const removeSelectedCardFromGame = () => {
        allShuffleCards.splice(0, 1)
    }
    const nextPlayer = (localPlayersLeft) => {
        if (localPlayersLeft.length > 0) {
            const copyActualPlayers = [...localPlayersLeft]
            const localActualPlayer = copyActualPlayers[0];
            copyActualPlayers.splice(0, 1)
            setPlayersLeft(copyActualPlayers)
            changePrintedActualPlayer(localActualPlayer)
            turnCount++;
        } else {
            nextPlayer(shuffledPlayers);
            roundCount++;
            turnCount = 1
        }
    }

    const changePrintedActualPlayer = (localActualPlayer) => {
        setActualPlayer(localActualPlayer);
        document.documentElement.style.setProperty('--color-actual-user', localActualPlayer.color);
    }


    useEffect(init, []);

    return (
        <div>
            {shuffledPlayers &&
            <PlayerContext.Provider value={{
                actualPlayer,
                setActualPlayer,
                playersLeft,
                setPlayersLeft,
                shuffledPlayers,
                setShuffledPlayers
            }}>
                {noMoreCards && <p>{t('There is no more cards. Please, reset game to keep playing')}</p>}
                {!noMoreCards && <>
                    <div className={"u-transition " + (isPlaying ? 'u-hide' : '')}>
                        <h3 className={"my-0 b-text b-text--m"}>{t("Turn") + " " + turnCount + "/" + shuffledPlayers.length + " - " + t("Round") + " " + roundCount}</h3>
                        <SelectRandomUser onDrawCard={playerSelected}/>
                    </div>
                    <div className={"u-transition " + (!isPlaying ? 'u-hide' : '')}><BoardGame
                        onDone={selectedCardAndChangePlayer}
                        card={actualCard}/></div>
                </>}
            </PlayerContext.Provider>
            }
        </div>
    )
}

export const duplicateAllCards = (cards, duplicateCards) => {
    const allCards = [...cards];

    for (const card of cards) {
        const foundCard = duplicateCards.find(duplicateCard => duplicateCard.card.data.id === card.id);
        if (foundCard) {
            for (let i = 0; i < foundCard.count - 1; i++) {
                allCards.push(card)
            }
        }
    }

    return allCards
}
