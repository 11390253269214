export const typesConst = {
    Question: {icon:'icon-question'},
    Dare: {icon:'icon-exclamation'},
    Mime: {icon:'icon-mime'},
    Draw: {icon:'icon-draw'},
    Effect: {icon:'icon-left-arrow'},
    "Banned Words": {icon:'icon-cancel'},
}

export const typesListConst = ['Question', 'Dare', 'Mime','Draw', 'Banned Words']
