import React from "react";
import { typesConst } from "../../consts/types.const";

export function CardsList ({ cards, cardsAction, onClick, onAnswerClick = () =>{}, showCount = false,deckIdCards = [] }) {
    return (

        cards.map((card, i) => card.attributes.description && card.attributes.description.length > 0 &&
            <div key={i} className="d-flex align-items-center my-6">
                <span onClick={onClick ? () => onClick(card) : undefined}
                      className={"mr-5 b-badge b-badge--s " + (typesConst[card.attributes.type].icon) + " " + (card.isAdded ? 'b-badge--active' : '')}/>
                <div>
                    <h4 style={{whiteSpace: "pre-line"}}>{showCount ? (card.count ? card.count : 1) + " x " : ""}{card.attributes.description}</h4>
                    <span onClick={() => {if(card.isNewCard){onAnswerClick()}}} className={"b-text b-text--s" +
                    " b-text--sec"}>{card.attributes.answer}</span></div>
                {cardsAction && cardsAction.map((cardAction, i) => cardAction.show &&
                    <span key={i} className={"ml-4 b-mini-card" +
                    " b-mini-card--l " + ((deckIdCards && deckIdCards.includes(card.id)) ? 'icon-cancel' : cardAction.icon)}
                          onClick={() => cardAction.onClick(card)}/>)}
            </div>)
    )
}
