import React, { useState } from 'react';
import { PlayersList } from "./components/PlayersList/PlayersList";
import { PlayersForm } from "./components/PlayersForm/PlayersForm";
import { playersColorsConst } from "../../shared/consts/playersColors.const";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function PlayersPage () {

    const { t } = useTranslation();
    const localStoragePlayers = localStorage.getItem('players');

    const [players, setPlayers] = useState(localStoragePlayers ? JSON.parse(localStoragePlayers) : []);

    const createPlayer = (player) => {
        player.color = playersColorsConst[players.length];
        player.score = 0;
        const newPlayers = [...players, player];
        updatePlayers(newPlayers);
    }

    const removePlayer = (i) => {
        const copyPlayers = [...players];
        copyPlayers.splice(i, 1);
        updatePlayers(copyPlayers);
    }

    const editPlayer = () => {
        updatePlayers();

    }

    const updatePlayers = (newPlayers) => {
        setPlayers(newPlayers);
        localStorage.setItem('players', JSON.stringify(newPlayers))
    }

    return (<div>
        <h2>{t('Add players')}</h2>
        <PlayersForm fnCreatePlayer={createPlayer}/>
        <div className={"pt-6"}>
            <PlayersList players={players} fnRemovePlayer={removePlayer} fnEditPlayer={editPlayer}/>
        </div>
        <div className={"d-flex justify-content-end"}>
            <Link className={players.length > 0 ? "b-text-start" : "b-text-start u-disabled"}
                  to="/select-deck">{t('Done')}</Link>
        </div>

    </div>)
}
